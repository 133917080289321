import { Indicators } from "@/utils/enums/indicators";
import { IndicatorType } from "@/utils/enums/indicatorType";
import { get } from "../api";
import { Paginated } from "@/@types/paginated";
import { PregnantPatient } from "@/@types/PregnantPatient";
import { BasePatient } from "@/@types/BasePatient";

export type IndicatorResume = {
	valid: number;
	pendingEligible: number;
	pendingIneligible: number;
};

type IndicatorParams = {
	uf: string;
	ibgeCode: string;
	pageNumber: number;
	indicator: Indicators;
	indicatorType: IndicatorType | null;
	cnes: string;
	ine: string;
	searchBirthDateCitizen: string;
	searchCitizenFullName: string;
	quarter: string;
	pageSize?: number;
};

type IndicatorResumeParams = {
	indicator: Indicators;
	quarter: string;
	uf: string;
	ibgeCode: string;
	cnes: string;
	ine: string;
};

export async function handleIndicator1(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<PregnantPatient>>(`/indicator/v1/getindicator`, {
		...indicatorParams,
		indicator: 1,
	});
	return response.data;
}

export async function handleIndicator2(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<PregnantPatient>>(`/indicator/v1/getindicator`, {
		...indicatorParams,
		indicator: 2,
	});
	return response.data;
}

export async function handleIndicator3(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<PregnantPatient>>(`/indicator/v1/getindicator`, {
		...indicatorParams,
		indicator: 3,
	});
	return response.data;
}

export async function handleIndicator4(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<BasePatient>>(`/indicator/v1/getindicator`, {
		...indicatorParams,
		indicator: 4,
	});
	return response.data;
}

export async function handleIndicator5(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<BasePatient>>(`/indicator/v1/getindicator`, {
		...indicatorParams,
		indicator: 5,
	});
	return response.data;
}

export async function handleIndicator6(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<BasePatient>>(`/indicator/v1/getindicator`, {
		...indicatorParams,
		indicator: 6,
	});
	return response.data;
}

export async function handleIndicator7(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<BasePatient>>(`/indicator/v1/getindicator`, {
		...indicatorParams,
		indicator: 7,
	});
	return response.data;
}

//IndicatorsResumes

export async function handleIndicator1Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(`/indicator/v1/getResume`, {
		...indicatorResumeParams,
		indicator: 1,
	});
	return response.data;
}

export async function handleIndicator2Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(`/indicator/v1/getResume`, {
		...indicatorResumeParams,
		indicator: 2,
	});
	return response.data;
}

export async function handleIndicator3Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(`/indicator/v1/getResume`, {
		...indicatorResumeParams,
		indicator: 3,
	});
	return response.data;
}

export async function handleIndicator4Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(`/indicator/v1/getResume`, {
		...indicatorResumeParams,
		indicator: 4,
	});
	return response.data;
}

export async function handleIndicator5Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(`/indicator/v1/getResume`, {
		...indicatorResumeParams,
		indicator: 5,
	});
	return response.data;
}

export async function handleIndicator6Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(`/indicator/v1/getResume`, {
		...indicatorResumeParams,
		indicator: 6,
	});
	return response.data;
}

export async function handleIndicator7Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(`/indicator/v1/getResume`, {
		...indicatorResumeParams,
		indicator: 7,
	});
	return response.data;
}
