import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CardBody from "react-bootstrap/CardBody";

import {
	CityResumeChildBirth,
	fetchResumeChildBirth,
	ResumeChildBirth,
} from "@/services/federalUnit/attendanceService";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";
import { removeDuplicates } from "@/utils/genericals";
import { Period } from "@/@types/Period";

import { BarChart } from "@/components/Charts/BarChart";
import { LegendData } from "@/components/LegendData";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { DonutChart } from "@/components/Charts/DonutChart";

type Props = { uf: string };

export function ChildBirth({ uf }: Props) {
	const [resumeChildBirths, setResumeChildBirths] = useState<CityResumeChildBirth[]>([]);

	async function handleResumeChildBirth() {
		return await fetchResumeChildBirth({
			uf: uf,
			startDate: dayjs().subtract(12, "month").format("YYYY-MM-DD"),
			endDate: dayjs().format("YYYY-MM-DD"),
		});
	}

	async function fetch() {
		setResumeChildBirths(await handleResumeChildBirth());
	}

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const periodResumes = resumeChildBirths
		?.map((resume) => resume.resumeChildBirth)
		.reduce((acc, current) => [...acc, ...current], []);
	const periods = removeDuplicates<Period[]>(periodResumes?.map((resume) => resume.period));

	const getChildBirthResume = (
		resumeChildBirths: CityResumeChildBirth[],
		key: keyof ResumeChildBirth
	) => {
		return (
			resumeChildBirths?.map((resume) =>
				resume.resumeChildBirth
					?.map((resumeChildBirth) => (resumeChildBirth[key] as number) || 0)
					.reduce((acc, curr) => acc + curr, 0)
			) || []
		);
	};

	const getChildBirthByPeriodResume = (
		periods: Period[],
		periodResumes: ResumeChildBirth[],
		key: keyof ResumeChildBirth
	) => {
		return (
			periods?.map((period) =>
				periodResumes
					.filter(
						(periodResume) =>
							periodResume.period.year === period.year &&
							periodResume.period.month === period.month
					)
					.reduce((acc, resume) => acc + ((resume[key] as number) || 0), 0)
			) || []
		);
	};

	const normalChildBirthResume = getChildBirthByPeriodResume(
		periods,
		periodResumes,
		"totalChildBirthNormal"
	);
	const cesareanChildBirthResume = getChildBirthByPeriodResume(
		periods,
		periodResumes,
		"totalChildBirthCesarean"
	);
	const normalStillBirthResume = getChildBirthByPeriodResume(
		periods,
		periodResumes,
		"totalStillBirthNormal"
	);
	const cesareanStillBirthResume = getChildBirthByPeriodResume(
		periods,
		periodResumes,
		"totalStillBirthCesarean"
	);
	const abortionResume = getChildBirthByPeriodResume(periods, periodResumes, "totalAbortion");
	const unspecifiedResume = getChildBirthByPeriodResume(
		periods,
		periodResumes,
		"totalUnspecified"
	);

	return (
		<>
			<Row>
				<Col sm={12} md={12} lg={12} xl={7}>
					<Card className="h-100">
						<Card.Header>
							<Card.Title>
								<label className="me-2"> Partos por cidade </label>
								<CardOverlayTrigger
									children={
										<LegendData
											ClassNamesColors={[
												"text-primary",
												"text-success",
												"text-warning",
												"text-danger",
												"text-info",
												"text-muted",
											]}
											titles={[
												"Normal",
												"Cesário",
												"Natimorto (normal)",
												"Natimorto (cesário)",
												"Aborto",
												"Não especificado",
											]}
										/>
									}
								/>
							</Card.Title>
						</Card.Header>
						<CardBody className="overflow-auto" style={{ maxHeight: 535 }}>
							<BarChart
								categories={
									resumeChildBirths?.map((resume) => resume.city?.locale) || []
								}
								series={[
									{
										name: "Normal",
										data: getChildBirthResume(
											resumeChildBirths,
											"totalChildBirthNormal"
										),
									},
									{
										name: "Cesário",
										data: getChildBirthResume(
											resumeChildBirths,
											"totalChildBirthCesarean"
										),
									},
									{
										name: "Natimorto (normal)",
										data: getChildBirthResume(
											resumeChildBirths,
											"totalStillBirthNormal"
										),
									},
									{
										name: "Natimorto (cesário)",
										data: getChildBirthResume(
											resumeChildBirths,
											"totalStillBirthCesarean"
										),
									},
									{
										name: "Aborto",
										data: getChildBirthResume(
											resumeChildBirths,
											"totalAbortion"
										),
									},
									{
										name: "Não especificado",
										data: getChildBirthResume(
											resumeChildBirths,
											"totalUnspecified"
										),
									},
								]}
								isStacked
							/>
						</CardBody>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={12} xl={5} className="d-flex flex-column gap-2">
					<Card className="h-50">
						<Card.Header>
							<Card.Title>Finalizações gestacionais</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center align-items-center p-0">
							<DonutChart
								series={[
									normalChildBirthResume.reduce((acc, curr) => acc + curr, 0),
									cesareanChildBirthResume.reduce((acc, curr) => acc + curr, 0),
									normalStillBirthResume.reduce((acc, curr) => acc + curr, 0),
									cesareanStillBirthResume.reduce((acc, curr) => acc + curr, 0),
									abortionResume.reduce((acc, curr) => acc + curr, 0),
									unspecifiedResume.reduce((acc, curr) => acc + curr, 0),
								]}
								width="100%"
								labels={[
									"Normal",
									"Cesáreo",
									"Natimorto (normal)",
									"Natimorto (cesário)",
									"Aborto",
									"Não especificado",
								]}
							/>
						</Card.Body>
					</Card>
					<Card className="h-50">
						<Card.Header>
							<Card.Title>Nativivos x Natimortos</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center align-items-center p-0">
							<DonutChart
								series={[
									normalChildBirthResume.reduce((acc, curr) => acc + curr, 0) +
										cesareanChildBirthResume.reduce(
											(acc, curr) => acc + curr,
											0
										),
									normalStillBirthResume.reduce((acc, curr) => acc + curr, 0) +
										cesareanStillBirthResume.reduce(
											(acc, curr) => acc + curr,
											0
										) +
										abortionResume.reduce((acc, curr) => acc + curr, 0),
								]}
								width="100%"
								labels={["Nativivo", "Natimorto"]}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="px-2">
				<Card className="m-2 h-100">
					<Card.Header>
						<Card.Title>Partos por período</Card.Title>
					</Card.Header>
					<Card.Body className="overflow-auto">
						<BarChart
							series={[
								{
									name: "Normal",
									data: normalChildBirthResume,
								},
								{
									name: "Cesário",
									data: cesareanChildBirthResume,
								},
								{
									name: "Natimorto (normal)",
									data: normalStillBirthResume,
								},
								{
									name: "Natimorto (cesário)",
									data: cesareanStillBirthResume,
								},
								{
									name: "Aborto",
									data: abortionResume,
								},
								{
									name: "Não especificado",
									data: unspecifiedResume,
								},
							]}
							categories={
								periods?.map(
									(resume) => `${resume?.year}/${SHORT_MONTHS[resume?.month - 1]}`
								) || []
							}
							isVertical
							isStacked
						/>
					</Card.Body>
				</Card>
			</Row>
		</>
	);
}
