import { AmountVisits } from "@/@types/AmountVisits";
import { get } from "../api";
import { Professional } from "@/@types/Professional";
import { CitizensConditionalResume } from "@/@types/CitizenConditionalResume";
import { HomeVisitConclusionType } from "@/@types/HomeVisitConclusionType";
import { Paginated } from "@/@types/paginated";
import { HomeVisit } from "@/@types/HomeVisit";

type ProfessionalAcsDashboard = AmountVisits & {
	professional: Professional;
};

export type VisitsResume = {
	totalAcs: number;
	totalMC: number;
	totalFA: number;
	totalFamilies: number;
	totalResidences: number;
	totalOtherResidences: number;
};

type HomeVisitsParams = {
	uf: string;
	ibgeCode: string;
	ine: string;
	cnes: string;
	professionalCns: string;
	startDate: string;
	endDate: string;
	aggregator: number;
};

type HomeVisitsConditionalResumeParams = {
	uf: string;
	ibgeCode: string;
	ine: string;
	cnes: string;
	professionalCns: string;
	startDate: string;
	endDate: string;
};

type HomeVisitParamsPaginated = {
	uf: string;
	ibgeCode: string;
	fullName: string;
	professionalCns: string;
	homeVisitConclusionTypeId?: number;
	startDate: string;
	endDate: string;
	pageNumber: number;
	pageSize: number;
};

export async function fetchVisitsResume(params: HomeVisitsParams) {
	const response = await get<VisitsResume>(`homeVisit/v1/getVisitResume`, params);
	return response.data;
}

export async function fetchProfessionalVisits(params: HomeVisitsParams) {
	const response = await get<ProfessionalAcsDashboard[]>(`homeVisit/v1/getDashboard`, params);
	return response.data;
}

export async function fetchCitizensConditionalResume(params: HomeVisitsConditionalResumeParams) {
	const response = await get<CitizensConditionalResume>(`homeVisit/v1/getResumeByCitizens`, params);
	return response.data;
}

export async function fetchHomeVisitConclusionType(params: { uf: string; ibgeCode: string }) {
	const response = await get<HomeVisitConclusionType[]>(`homeVisitConclusionType/v1/GetAll`, params);
	return response.data;
}

export async function fetchHomeVisitPaginated(params: HomeVisitParamsPaginated) {
	const response = await get<Paginated<HomeVisit>>(`homeVisit/v1/getPaginated`, params);
	return response.data;
}
