import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Cbo } from "@/@types/Cbo";
import { HealthTeam } from "@/@types/HealthTeam";
import { HealthUnit } from "@/@types/HealthUnit";
import { Procedure } from "@/@types/Procedure";
import { Professional } from "@/@types/Professional";

type ExamsCardsProps = {
	procedure?: Procedure;
	professional?: Professional;
	healthTeam?: HealthTeam;
	healthUnit?: HealthUnit;
	isPendingExam?: boolean;
	cbo?: Cbo;
};

export function ExamsCards({
	healthTeam,
	procedure,
	professional,
	isPendingExam,
	cbo,
}: ExamsCardsProps) {
	return (
		<Row className="d-flex align-items-center">
			<Col sm={1} md={1} lg={1} xl={1} className="my-2">
				{isPendingExam ? (
					<FaExclamationTriangle title="Exame requerido" className="text-warning fs-1" />
				) : (
					<FaCheck title="Exame avaliado" className="text-success fs-1" />
				)}
			</Col>
			<Col>
				<p className="fw-bold text-primary fs-5">
					Procedimento/Exame:
					<span className="ms-1 text-dark fw-normal">
						{procedure?.name ? `${procedure.procedureCode} - ${procedure.name}` : "N/A"}
					</span>
				</p>
				<p>
					<span className="fw-bold text-primary fs-5">
						Profissional:
						<span className="ms-1 text-dark fw-normal">
							{professional?.name || "N/A"}
						</span>
					</span>
				</p>
				<p>
					<span className="fw-bold text-primary fs-5">
						Categoria:
						<span className="ms-1 text-dark fw-normal">
							{cbo?.description || "N/A"}
						</span>
					</span>
				</p>
				<p className="fw-bold text-primary fs-5">
					Equipe:
					<span className="ms-1 text-dark fw-normal">
						{healthTeam?.ine ? `${healthTeam?.ine}-${healthTeam?.name}` : "N/A"}
					</span>
				</p>
			</Col>
		</Row>
	);
}
