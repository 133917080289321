import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
	UserPersonalInfo,
	userPersonalInfoRegistrationSchema,
} from "@/utils/Schemas/registrationSchema";
import classNamesGroups from "clsx";
import { onlyNumbers } from "@/utils/genericals";
import { checkUserExists } from "@/services/auth/accountService";
import { useDebounce } from "@/hooks/useDebounce";
import statesAndCities from "@/utils/static/states-and-cities.json";
import { StatesAndCities } from "@/@types/statesAndCities";

import { NextButton } from "../NextButton";

type Props = {
	handleGoToNextStep: () => void;
	handleSubmit: (personalInfo: UserPersonalInfo) => void;
	isNextButtonDisabled: boolean;
	userPersonalInfo?: UserPersonalInfo;
};

const initialValues: UserPersonalInfo = {
	state: "",
	city: "",
	occupation: "",
	name: "",
	identifier: "",
	email: "",
	phone: "+55",
};

const occupations = [
	"Secretário de Saúde",
	"Secretário Adjunto",
	"Coordenador de Secretaria",
	"Coordenador Externo",
	"Enfermeiro(a)",
	"Técnico em Enfermagem",
	"Médico (a)",
	"Cirurgião Dentista",
	"Auxiliar/Técnico de Saúde Bucal",
	"Agente Comunitário de Saúde",
	"Outro",
];

export function UserPersonalInfoForm({
	handleGoToNextStep,
	handleSubmit,
	userPersonalInfo,
	isNextButtonDisabled,
}: Props) {
	const navigate = useNavigate();
	const { states, cities } = statesAndCities as StatesAndCities;
	const [isExistingIdentifier, setIsExistingIdentifier] = useState(false);
	const [isExistingEmail, setIsExistingEmail] = useState(false);
	const [otherOccupation, setOtherOccupation] = useState("");
	const formik = useFormik({
		initialValues: { ...initialValues, ...userPersonalInfo } as UserPersonalInfo,
		validationSchema: userPersonalInfoRegistrationSchema,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});
	const debouncedMail = useDebounce(formik.values.email, 1500);

	async function handleCheckUserExistsByIdentifier(identifier: string) {
		if (onlyNumbers(identifier).length !== 11) return;
		const exists = await checkUserExists(onlyNumbers(identifier), undefined);
		setIsExistingIdentifier(exists === undefined || exists);
		if (exists) {
			toast.error("Cpf já cadastrado, tente login ou Recupere a senha");
		}
	}

	async function handleCheckUserExistsByEmail(email: string) {
		const exists = await checkUserExists(undefined, email);
		setIsExistingEmail(exists === undefined || exists);
		if (exists) {
			toast.error("Email já cadastrado, tente login ou Recupere a senha");
		}
	}

	async function handleChangeValues() {
		const isValid = await userPersonalInfoRegistrationSchema.isValid(formik.values);
		if (isValid && !isExistingEmail && !isExistingIdentifier) {
			handleSubmit({
				...formik.values,
				occupation:
					formik.values.occupation !== "Outro"
						? formik.values.occupation
						: otherOccupation,
			});
		} else {
			handleSubmit({} as UserPersonalInfo);
		}
	}

	useEffect(() => {
		if (!formik.errors.email) {
			handleCheckUserExistsByEmail(formik.values.email);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedMail]);

	useEffect(() => {
		if (!formik.errors.identifier) {
			handleCheckUserExistsByIdentifier(formik.values.identifier);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.identifier, formik.errors.identifier]);

	useEffect(() => {
		handleChangeValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values, isExistingIdentifier, isExistingEmail]);

	return (
		<section>
			<form noValidate autoComplete="off">
				<section className="text-center">
					<p className="fw-bolder fs-1 ">Crie sua conta</p>
					<p className="fs-6">
						Já possui uma conta? Realize o{" "}
						<Link to="/auth" className="text-decoration-underline">
							{" "}
							Login{" "}
						</Link>
					</p>
				</section>
				<section>
					<p className="fs-7 mb-1 fw-bolder">CPF</p>
					<p className="my-2">
						<InputMask
							{...formik.getFieldProps("identifier")}
							id="identifier"
							mask="999.999.999-99"
							name="identifier"
							placeholder="000.000.000-00"
							autoComplete="off"
							autoFocus
							value={formik.values.identifier}
							className={classNamesGroups(
								"form-control form-control-sm p-2 m-0 fs-3 fw-normal",
								{
									"is-invalid":
										isExistingIdentifier ||
										(formik.errors.identifier && formik.touched.identifier),
								},
								{
									"is-valid":
										formik.touched.identifier &&
										!formik.errors.identifier &&
										!isExistingIdentifier,
								}
							)}
						/>
					</p>
				</section>
				<section>
					<p className="fs-7 my-2 fw-bolder">Nome</p>
					<input
						{...formik.getFieldProps("name")}
						type="text"
						name="name"
						id="name"
						placeholder="João Almeida de Sousa Silva"
						autoComplete="off"
						className={classNamesGroups(
							"form-control form-control-sm p-2 fs-3 fw-normal",
							{
								"is-invalid": formik.touched.name && formik.errors.name,
							},
							{
								"is-valid": formik.touched.name && !formik.errors.name,
							}
						)}
					/>
				</section>
				<section className="d-flex align-items-center">
					<section className="w-25">
						<p className="fs-7 my-2 fw-bolder">UF</p>
						<p>
							<select
								{...formik.getFieldProps("state")}
								name="state"
								id="state"
								className="form-select form-select-sm px-5  fs-3 text-muted fw-normal"
								autoComplete="off"
							>
								<option value="" disabled>
									UF
								</option>
								{states.map((state) => (
									<option
										key={state.abbreviation}
										value={state.abbreviation}
										className="text-dark"
									>
										{state.abbreviation}
									</option>
								))}
							</select>
						</p>
					</section>
					<section className="ms-2 w-75">
						<p className="fs-7 my-2 fw-bolder">Cidade</p>
						<p>
							<select
								{...formik.getFieldProps("city")}
								name="city"
								id="city"
								className="form-select form-select-sm fs-3 text-muted fw-normal"
								autoComplete="off"
							>
								<option value="" disabled>
									Cidade
								</option>
								{formik.values.state &&
									cities
										.filter(
											(city) =>
												city.microrregiao.mesorregiao.UF.sigla ===
												formik.values.state
										)
										.map((city) => (
											<option
												key={city.id}
												value={JSON.stringify(city)}
												className="text-dark"
											>
												{city.nome}
											</option>
										))}
							</select>
						</p>
					</section>
				</section>
				<section>
					<p className="fs-7 my-2 fw-bolder">Cargo</p>
					<select
						{...formik.getFieldProps("occupation")}
						name="occupation"
						id="occupation"
						className="form-select form-select-sm fs-3 text-muted fw-normal"
						autoComplete="off"
					>
						<option value="" disabled>
							Qual seu cargo
						</option>
						{occupations.map((occupation, index) => (
							<option key={`occupation-${index}`} value={occupation}>
								{occupation}
							</option>
						))}
					</select>
					{formik.values.occupation === "Outro" && (
						<p>
							<label htmlFor="othet" className="sr-only ">
								Outro Cargo:
							</label>
							<input
								className="form-control form-control-sm fs-3 fw-normal"
								type="text"
								placeholder="Digite seu cargo"
								autoComplete="off"
								value={otherOccupation}
								onChange={(e) => {
									setOtherOccupation(e.target.value);
								}}
							/>
						</p>
					)}
				</section>
				<section>
					<p className="fs-7 my-2 fw-bolder">E-mail</p>
					<input
						{...formik.getFieldProps("email")}
						type="email"
						name="email"
						id="email"
						autoComplete="off"
						className={classNamesGroups(
							"form-control form-control-sm fs-3 fw-normal",
							{
								"is-invalid": isExistingEmail || (formik.touched.email && formik.errors.email),
							},
							{
								"is-valid": formik.touched.email && !formik.errors.email && !isExistingEmail,
							}
						)}
					/>
				</section>
				<section>
					<p className="fs-7 my-2 fw-bolder">Telefone</p>
					<InputMask
						{...formik.getFieldProps("phone")}
						mask="+99 (99) 99999-9999"
						name="phone"
						placeholder="(00) 00000-0000"
						autoComplete="off"
						className={classNamesGroups(
							"form-control form-control-sm fs-3 fw-normal",
							{
								"is-invalid": formik.touched.phone && formik.errors.phone,
							},
							{
								"is-valid": formik.touched.phone && !formik.errors.phone,
							}
						)}
					/>
				</section>
			</form>
			<Row className="justify-content-evenly mt-4">
				<Col className="d-flex justify-content-center">
					<NextButton
						text={"Voltar"}
						form="registration-form"
						previous
						className="bg-muted w-100"
						onClick={() => navigate("/auth")}
					/>
				</Col>
				<Col className="d-flex justify-content-center">
					<NextButton
						text={"Avançar"}
						form="registration-form"
						className="w-100"
						disabled={isNextButtonDisabled}
						onClick={handleGoToNextStep}
						style={{ backgroundColor: "#008FFB" }}
					/>
				</Col>
			</Row>
		</section>
	);
}
