import { AttendanceAggregatorEnum } from "@/utils/enums/AttendanceAggregatorEnum";
import { get } from "../api";
import { BasePatient } from "@/@types/BasePatient";
import { Attendance } from "@/@types/Attendance";
import { DentalAttendance } from "@/@types/DentalAttendance";
import { AttendanceProcedureReqEval } from "@/@types/AttendanceProcedureReqEval";
import { Vaccination } from "@/@types/Vaccination";
import { Problem } from "@/@types/Problem";
import { WeightClassificationEnum } from "@/utils/enums/WeightClassificationEnum";
import { RacialColorEnum } from "@/utils/enums/RacialColorEnum";
import { PregnancyTypeEnum } from "@/utils/enums/PregnancyTypeEnum";
import { Cbo } from "@/@types/Cbo";
import { Period } from "@/@types/Period";
import { Paginated } from "@/@types/paginated";

export type ResumeGrouped<T, K> = {
	key: T;
	total: number;
	values?: K[];
};

export type AggregatedDashboard = {
	aggregator: AttendanceAggregatorEnum;
	name: string;
	values: ResumeGrouped<string, object>[];
};

export type ResumePregnant = {
	resumeChildBirthType: ResumeGrouped<string | null, object>[];
	resumePregnancyStatus: ResumeGrouped<string | null, object>[];
	resumePuerperalStatus: ResumeGrouped<string | null, object>[];
	resumeGestationalAge: ResumeGrouped<string | null, object>[];
	resumeGestationalAgeTrimester: ResumeGrouped<number | null, object>[];
	resumeHivAndSifilisStatus: ResumeGrouped<string | null, object>[];
	resumeRacialColor: ResumeGrouped<RacialColorEnum | null, object>[];
	resumeHighRisk: ResumeGrouped<number | null, object>[];
	resumeChildBirthByPeriodAndType: ResumeGrouped<
		Period | null,
		ResumeGrouped<string | null, object>[]
	>[];
	averageGestationalAge: number;
	averagePregnantAge: number;
};

export type Pregnancy = BasePatient & {
	socialName: string;
	firstDumDate: string;
	firstAttendanceDate: string;
	dppDate: string;
	gender: number;
	inPreliminaryReport: boolean;
	preliminaryReportStatus: number;
	phone?: string;
	alcoholicStatus?: number;
	smokerStatus?: number;
	haveDiabetes?: number;
	haveHighBloodPressure?: number;
	lastIndividualRecordDate?: string;
	attendances: Attendance[];
	dentalAttendances?: DentalAttendance[];
	childBirthDate: string;
	puerperalAttendanceProcedures: AttendanceProcedureReqEval[];
	pregnancyProcedures?: AttendanceProcedureReqEval[];
	pregnancyVaccinations?: Vaccination[];
	childBirthType?:
		| "normal"
		| "cesarean"
		| "normalstillbirth"
		| "cesareanstillbirth"
		| "abortion"
		| "unspecified"
		| "unregistered";
	childBirthProblem?: Problem;
	attendanceStatus: string;
	puerperalStatus: string;
	pregnancyStatus: string;
	highRiskStatus?: number;
	plannedPregnancyStatus?: number;
	weightClassification?: WeightClassificationEnum;
	racialColor?: RacialColorEnum;
	pregnancyType?: PregnancyTypeEnum;
	firstGestationalAge?: number;
	lastGestationalAge?: number;
	citizenCbo?: Cbo;
};

type DashboardAttendanceParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	startDate: string;
	endDate: string;
	aggregator?: number;
};

type EpidemicDashboardParams = {
	uf: string;
	ibgeCode: string;
	startDate: string;
	endDate: string;
	epidemicType: string;
};

type PregnantsWithChildBirthParams = {
	startDate: string;
	endDate: string;
	fullName: string;
	ibgeCode: string;
	pageNumber: number;
	uf: string;
	orderBy: string;
};

type ResumePregnantsWithChildBirthParams = {
	startDate: string;
	endDate: string;
	ibgeCode: string;
	uf: string;
};

export async function handleDashboardAttendance(params: DashboardAttendanceParams) {
	const response = await get<AggregatedDashboard[]>(`/Attendance/v1/GetDashboard`, params);
	return response.data;
}

export async function handleEpidemicDashboard(params: EpidemicDashboardParams) {
	const response = await get<AggregatedDashboard[]>(`/Attendance/v1/GetEpidemicDashboard`, params);
	return response.data;
}

export async function handlePregnantsWithChildBirthPaginated(
	params: PregnantsWithChildBirthParams
) {
	const response = await get<Paginated<Pregnancy>>(
		`/Attendance/v1/GetPregnantsWithChildBirthPaginated`,
		params
	);
	return response.data;
}

export async function handlePregnantsWithChildBirth(
	params: Omit<PregnantsWithChildBirthParams, "pageNumber">
) {
	const response = await get<Pregnancy[]>(`/Attendance/v1/GetPregnantsWithChildBirth`, params);
	return response.data;
}

export async function handleResumePregnantsWithChildBirth(
	params: ResumePregnantsWithChildBirthParams
) {
	const response = await get<ResumePregnant>(`/Attendance/v1/ResumePregnantsWithChildBirth`, params);
	return response.data;
}
