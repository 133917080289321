import { MutableRefObject } from "react";

import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { Paginated } from "@/@types/paginated";
import { BasePatient } from "@/@types/BasePatient";

type Props = {
	tableRef: MutableRefObject<HTMLTableElement | null>;
	patient: Paginated<BasePatient>;
};

export function TableIndicator6ExportPdf({ tableRef, patient }: Props) {
	return (
		<Table responsive className="table-rounded print-container border gy-7 gs-3" ref={tableRef}>
			<thead>
				<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
					<th>CIDADÃO</th>
					<th>DN</th>
					<th>CPF/CNS</th>
					<th>EQUIPE</th>
					<th>MC</th>
					<th>AUTORREFERIDO </th>
					<th>CONDIÇÃO AVALIADA</th>
					<th>ULTIMA CONSULTA</th>
					<th>ULTIMA AFERIÇÃO</th>
					<th>PRÓXIMA AVALIAÇÃO</th>
				</tr>
			</thead>
			<tbody>
				{patient?.data?.map((patient, index) => (
					<tr key={`patient-pregnant-${index}`}>
						<td title="nome" className="text-nowrap">
							{patient.fullName || "N/A"}
						</td>
						<td className="text-nowrap">
							{patient.birthdate
								? dayjs(patient.birthdate).format("DD/MM/YYYY")
								: "N/A"}
						</td>
						<td title="cpf" className="text-nowrap">
							{identifierOrNationalHealthCard(patient)}
						</td>
						<td title="equipe" className="text-nowrap">
							{patient.healthTeam?.name || "N/A"}
						</td>
						<td title="Micro Área" className="text-nowrap text-center">
							{patient.area || "N/A"}
						</td>
						<td title="autorreferido" className="text-nowrap text-center">
							{patient.cardiacRisk?.haveHypertensionCds ? "SIM" : "NÃO"}
						</td>
						<td title="condição avaliada">
							{patient.attendances && patient.attendances?.length > 0 ? "SIM" : "NÃO"}
						</td>
						<td title="ultima consulta" className="text-nowrap">
							{patient.attendances &&
							patient.attendances.length > 0 &&
							patient.attendances[0].date
								? dayjs(patient.attendances[0].date).format("DD/MM/YYYY")
								: "N/A"}
						</td>
						<td title="ultima aferição" className="text-nowrap">
							{patient.attendanceProcedures &&
							patient.attendanceProcedures.length > 0 &&
							patient.attendanceProcedures[0].date
								? dayjs(patient.attendanceProcedures[0].date).format("DD/MM/YYYY")
								: "N/A"}
						</td>
						<td title="Próxima avaliação">
							{patient.attendances &&
							patient.attendances?.length > 0 &&
							patient.attendances[0].date
								? dayjs(patient.attendances[0].date)
										.add(6, "month")
										.format("DD/MM/YYYY")
								: "N/A"}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
}
