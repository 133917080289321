import { User } from "@/@types/user";
import { get, post } from "../api";

type RegisterUserForm = {
    fullName: string,
    email: string,
    identifier: string,
    phone: string,
    occupation: string,
    ibgeCode: string,
    locale: string,
    federalUnitId?: number;
}

export async function checkUserExists(identifier: string) {
    const response = await get(
        "/User/v1/CheckUserExists",
        {identifier: identifier}
    );
    if(response.status !== undefined){
        return response.status === 200;
    }
}

export async function registerUser(params: RegisterUserForm) {
    const response = await post(
        "/user/v1/registeruser", 
        params
    );
    return response.status !== undefined;
}

export async function getUserInfoMasked(identifier: string) {
    const response = await get<User>(
        "/User/v1/GetUserInforMask",
        {identifier: identifier}
    );
    return response.data;
}	