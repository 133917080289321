import { HealthTeam } from "@/@types/HealthTeam";
import { HealthUnit } from "@/@types/HealthUnit";
import { Professional } from "@/@types/Professional";
import { get } from "../api";

export type TerritorialCitizenResume = {
	totalCitizens: number;
	totalMC: number;
	totalFA: number;
	totalFamilies: number;
	totalResidences: number;
	totalOtherBuildings: number;
	totalAcs: number;
};

export type ResumeHealthUnit = {
	healthUnit: HealthUnit;
	total: number;
};

export type ResumeHealthTeam = {
	healthTeam: HealthTeam;
	total: number;
};

export type ResumeByProfessional = {
	professional: Professional;
	total: number;
};

export type ResumeByPeriod = {
	period: {
		year: number;
		month: number;
	};
	total: number;
};

type TerritorialCitizenResumeParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

type TerritorialCitizenResumeByPeriodParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	startDate: string;
	endDate: string;
};

export async function fetchTerritorialCitizenResume(params: TerritorialCitizenResumeParams) {
	const response = await get<TerritorialCitizenResume>(`TerritorialCitizen/v1/getResume`, params);
	return response.data;
}

export async function fetchResumeByHealthUnits(params: TerritorialCitizenResumeParams) {
	const response = await get<ResumeHealthUnit[]>(`TerritorialCitizen/v1/GetResumeByHealthUnit`, params);
	return response.data;
}

export async function fetchResumeByHealthTeams(params: TerritorialCitizenResumeParams) {
	const response = await get<ResumeHealthTeam[]>(`TerritorialCitizen/v1/GetResumeByHealthTeam`, params);
	return response.data;
}

export async function fetchResumeByProfessionals(params: TerritorialCitizenResumeParams) {
	const response = await get<ResumeByProfessional[]>(
		`TerritorialCitizen/v1/GetResumeByProfessional`,
		params
	);
	return response.data;
}

export async function fetchResumeCitizensUpdatedByPeriod(
	params: TerritorialCitizenResumeByPeriodParams
) {
	const response = await get<ResumeByPeriod[]>(
		`TerritorialCitizen/v1/GetResumeCitizensUpdatedByPeriod`,
		params
	);
	return response.data;
}

export async function fetchResumeNewCitizensByPeriod(
	params: TerritorialCitizenResumeByPeriodParams
) {
	const response = await get<ResumeByPeriod[]>(
		`TerritorialCitizen/v1/GetResumeNewCitizensByPeriod`,
		params
	);
	return response.data;
}

export async function fetchTotalCitizens(params: TerritorialCitizenResumeParams & { ine: string }) {
	const response = await get<number>(`territorialCitizen/v1/getTotalCitizens`, params);
	return response.data;
}
