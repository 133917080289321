import dayjs from "dayjs";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { PregnantPatient } from "@/@types/PregnantPatient";

import { TimeLine } from "@/components/TimeLine";

type Props = ModalProps & {
	patientSelected: PregnantPatient;
};

export function ModalIndicator3({ patientSelected, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-4 text-primary">Paciente: </span>
							<span>{patientSelected?.fullName}</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-4 text-primary">DN: </span>
							<span>
								{patientSelected.birthdate
									? dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-4 text-primary">DUM: </span>
							<span>
								{patientSelected.firstDumDate
									? dayjs(patientSelected?.firstDumDate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-4 text-primary">DPP: </span>
							<span>
								{patientSelected.dppDate
									? dayjs(patientSelected?.dppDate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
					</Row>
				</section>
				<section className="d-flex flex-column gap-5">
					<TimeLine
						timeLineTitle="Atendimentos odontológicos"
						timeLineContents={patientSelected.dentalAttendances?.map(
							(attendance, index) => {
								return {
									content: (
										<Row key={index}>
											<article>
												<p>
													<strong className="text-primary me-2">
														Equipe:
													</strong>
													{patientSelected?.healthTeam?.ine
														? `${patientSelected.healthTeam?.ine}- 								
														${patientSelected.healthTeam?.name}`
														: "N/A"}
												</p>
											</article>

											<article>
												<strong className="text-primary">
													1º Profissional:{" "}
												</strong>
												<p>
													<strong className="me-2">Nome:</strong>
													{attendance.professional1?.name || "N/A"}
												</p>
												<p>
													<strong className="me-2">Categoria:</strong>
													{attendance.cbo1?.description || "N/A"}
												</p>
											</article>

											<article>
												<strong className="text-primary">
													2º Profissional:{" "}
												</strong>
												<p>
													<strong className="me-2">Nome:</strong>
													{attendance.professional2?.name || "N/A"}
												</p>
												<p>
													<strong className="me-2">Categoria:</strong>
													{attendance.cbo2?.description || "N/A"}
												</p>
											</article>
										</Row>
									),
									eventDate: attendance.date
										? dayjs(attendance.date).format("DD/MM/YYYY")
										: "N/A",
								};
							}
						)}
					/>
				</section>
			</Modal.Body>
		</Modal>
	);
}
