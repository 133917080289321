import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import { OrderBy } from "@/@types/OrderBy";

type Props<T> = OrderBy<T> & {
	handleChangeOrder: (property: keyof T) => void;
};

export function OrderByButton<T>({ handleChangeOrder, method, property, ...rest }: Props<T>) {
	return (
		<button
			className="border border-0 d-flex flex-column fs-9 cursor-pointer"
			onClick={() => handleChangeOrder(property)}
		>
			{!(method === "desc") && <FaChevronUp />}
			{!(method === "asc") && <FaChevronDown />}
		</button>
	);
}
