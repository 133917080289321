import { Account } from "@/@types/Account";
import { get } from "../api";
import { Period } from "@/@types/Period";

export type CityResumeChildBirth = {
	city: Account;
	resumeChildBirth: ResumeChildBirth[];
};

export type ResumeChildBirth = {
	totalChildBirthNormal?: number;
	totalChildBirthCesarean?: number;
	totalStillBirthNormal?: number;
	totalStillBirthCesarean?: number;
	totalAbortion?: number;
	totalUnspecified?: number;
	period: Period;
};

type ResumeChildBirthParams={
    uf: string;
    startDate: string;
    endDate: string;
}

export async function fetchResumeChildBirth(resumeChildBirthParams: ResumeChildBirthParams){
	const response = await get<CityResumeChildBirth[]>(`/FederalUnitAttendance/v1/ResumeChildBirth`,resumeChildBirthParams);
	return response.data;
}