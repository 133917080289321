import { get } from "../api";
import { Citizen } from "@/@types/Citizen";
import { Paginated } from "@/@types/paginated";
import { Problem } from "@/@types/Problem";

export type DiseaseResume = {
	recoveredCount: number;
	underTreatmentCount: number;
	untreatedCount: number;
};

export type DiseaseData = {
	firstIndividualRecordDate: string;
	lastIndividualRecordWithDiseaseYesDate: string;
	lastIndividualRecordWithDiseaseNoDate: string;
	citizen: Citizen;
	firstProblemDate: string;
	lastProblemDate: string;
	problems: Problem[];
	currentStatusDisease: boolean;
	status: number;
};

type EpidemicResumeParams = {
	uf: string;
	ibgeCode: string;
	startDate: string;
	endDate: string;
};

type EpidemicParams = {
	uf: string;
	ibgeCode: string;
	startDate: string;
	endDate: string;
	pageNumber: number;
	searchName: string;
	filterStatus: number | null;
};

export async function fetchCitizensWithTuberculosisResume(params: EpidemicResumeParams) {
	const response = await get<DiseaseResume>(
		`/Epidemic/v1/GetCitizensWithTuberculosisByPeriodResume`,
		params
	);
	return response.data;
}

export async function fetchCitizensWithTuberculosis(params: EpidemicParams) {
	const response = await get<Paginated<DiseaseData>>(
		`/Epidemic/v1/GetCitizensWithDetailsTuberculosis`,
		params
	);
	return response.data;
}

export async function fetchCitizensWithHansenResume(params: EpidemicResumeParams) {
	const response = await get<DiseaseResume>(`/Epidemic/v1/GetCitizensWithHansenByPeriodResume`, params);
	return response.data;
}

export async function fetchCitizensWithHansen(params: EpidemicParams) {
	const response = await get<Paginated<DiseaseData>>(`/Epidemic/v1/GetCitizensWithDetailsHansen`, params);
	return response.data;
}
