import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { BsSearch } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import {
	AggregatedDashboard,
	handleDashboardAttendance,
	ResumeGrouped,
} from "@/services/esus/attendanceService";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { getExpirationDateMidnight } from "@/utils/getExpirationDateMidnight";
import { AttendanceAggregatorEnum } from "@/utils/enums/AttendanceAggregatorEnum";

import { LoadingScreen } from "@/components/LoadingScreen";
import { AreaChart } from "@/components/Charts/AreaChart";
import { PieChart } from "@/components/Charts/PieChart";
import { BarChart } from "@/components/Charts/BarChart";
import { DonutChart } from "@/components/Charts/DonutChart";

export function AttendanceDashboard() {
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const { setTitle } = useLayout();
	const [isLoading, setIsloading] = useState(false);
	const [selectedOptionStartDate, setSelectedOptionStartDate] = useState<string>(
		dayjs().subtract(4, "months").format("YYYY-MM-DD")
	);
	const [selectedOptionEndDate, setSelectedOptionEndDate] = useState<string>(
		dayjs().format("YYYY-MM-DD")
	);
	const [aggregatedDashboards, setAggregatedDashboards] = useState<AggregatedDashboard[]>([]);
	const cacheExpirationDate = getExpirationDateMidnight();

	async function handleDashboard(aggregator?: number) {
		return handleDashboardAttendance({
			uf,
			ibgeCode,
			cnes,
			startDate: selectedOptionStartDate,
			endDate: selectedOptionEndDate,
			aggregator: aggregator,
		});
	}

	async function fetchDashboard() {
		const cacheKey = `[dashboardIndividualAttendance][${uf}][${ibgeCode}][${cnes}][${selectedOptionStartDate}][${selectedOptionEndDate}]`;
		setIsloading(true);
		setAggregatedDashboards(
			await fetchWithCache(cacheKey, cacheExpirationDate, handleDashboard)
		);
		setIsloading(false);
	}

	function filterAggregatedDashboard(aggregatorkey: keyof typeof AttendanceAggregatorEnum) {
		return (
			aggregatedDashboards.find(
				(aggregatedDashboard) =>
					aggregatedDashboard.aggregator === AttendanceAggregatorEnum[aggregatorkey]
			)?.values || []
		);
	}

	function aggregatedDashboardWithSort(
		aggregatorkey: keyof typeof AttendanceAggregatorEnum,
		attendanceKey: keyof ResumeGrouped<string, number>
	) {
		return (
			filterAggregatedDashboard(aggregatorkey)
				.sort((healthUnitA, healthUnitB) => {
					const valueA = healthUnitA.total as number;
					const valueB = healthUnitB.total as number;

					return (valueB || 0) - (valueA || 0);
				})
				?.map((attendance) => attendance[attendanceKey]) || []
		);
	}

	function aggregatedDashboard(
		aggregatorkey: keyof typeof AttendanceAggregatorEnum,
		attendanceKey: keyof ResumeGrouped<string, object>
	) {
		return (
			filterAggregatedDashboard(aggregatorkey)?.map(
				(attendance) => attendance[attendanceKey]
			) || []
		);
	}

	const periodsSeries = aggregatedDashboard("PERIOD", "total") as number[];
	const periodsCategories = aggregatedDashboard("PERIOD", "key") as string[];

	const ageGroupsSeries = aggregatedDashboard("AGEGROUP", "total") as number[];
	const ageGroupsCategories = aggregatedDashboard("AGEGROUP", "key") as string[];

	const healthUnitsSeries = aggregatedDashboardWithSort("HEALTHUNIT", "total") as number[];
	const healthUnitsCategories = aggregatedDashboardWithSort("HEALTHUNIT", "key") as string[];

	const healthTeamsSeries = aggregatedDashboardWithSort("HEALTHTEAM", "total") as number[];
	const healthTeamsCategories = aggregatedDashboardWithSort("HEALTHTEAM", "key") as string[];

	const ciapsSeries = aggregatedDashboardWithSort("CIAP", "total").slice(0, 10) as number[];
	const ciapsCategories = aggregatedDashboardWithSort("CIAP", "key").slice(0, 10) as string[];

	const cidsSeries = aggregatedDashboardWithSort("CID", "total").slice(0, 10) as number[];
	const cidsCategories = aggregatedDashboardWithSort("CID", "key").slice(0, 10) as string[];

	const gendersSeries = aggregatedDashboard("GENDER", "total") as number[];
	const gendersCategories = aggregatedDashboard("GENDER", "key") as string[];

	useEffect(() => {
		setTitle("Atendimentos individuais");
		fetchDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<LoadingScreen
				loading={isLoading}
				messages={[
					"Já estamos processando a consulta do periodo.",
					"aguarde alguns instantes.",
					"Estamos quase lá!",
				]}
			/>
			<Form
				onSubmit={(event) => {
					event.preventDefault();
					fetchDashboard();
				}}
			>
				<Row className="d-flex justify-content-between">
					<Col sm={12} lg={6} className="justify-content-start">
						<div className="d-flex  align-items-center gap-3">
							<Form.Label className="align-items-center fw-bolder">
								Período
							</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="date"
									placeholder="Data inicial"
									min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
									max={dayjs().format("YYYY/MM/DD")}
									defaultValue={dayjs().subtract(4, "M").format("YYYY-MM-DD")}
									onChange={(event) =>
										setSelectedOptionStartDate(event.target.value)
									}
								/>
							</InputGroup>
							<InputGroup className="mb-3">
								<Form.Control
									type="date"
									placeholder="data final"
									defaultValue={dayjs().format("YYYY-MM-DD")}
									min={selectedOptionStartDate}
									max={dayjs().format("YYYY-MM-DD")}
									onChange={(event) =>
										setSelectedOptionEndDate(event.target.value)
									}
								/>
							</InputGroup>
						</div>
					</Col>

					<Col sm={12} md={6} lg={2} className="justify-content-end">
						<Button
							className="d-flex align-items-center justify-content-center w-100"
							type="submit"
							disabled={isLoading}
						>
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</Col>
				</Row>
			</Form>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos mensais
						</Card.Header>
						<Card.Body>
							<AreaChart
								series={[{ data: periodsSeries, name: "Qtde. Atendimentos" }]}
								categories={periodsCategories}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Faixa etária
						</Card.Header>
						<Card.Body>
							<PieChart series={ageGroupsSeries} categories={ageGroupsCategories} />
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Gêneros
						</Card.Header>
						<Card.Body className="d-flex justify-content-center align-items-center p-0 ">
							<DonutChart series={gendersSeries} labels={gendersCategories} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por unidade
						</Card.Header>
						<Card.Body>
							<BarChart
								series={[{ data: healthUnitsSeries, name: "Total" }]}
								categories={healthUnitsCategories}
								categoryTextWidth={380}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por equipe
						</Card.Header>
						<Card.Body>
							<BarChart
								series={[{ data: healthTeamsSeries, name: "Total" }]}
								categories={healthTeamsCategories}
								categoryTextWidth={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={6}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							CIAP mais utilizados
						</Card.Header>
						<Card.Body>
							<BarChart
								series={[{ data: ciapsSeries, name: "Total" }]}
								categories={ciapsCategories}
								categoryTextWidth={100}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} lg={6}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							CID mais utilizados
						</Card.Header>
						<Card.Body>
							<BarChart
								series={[{ data: cidsSeries, name: "Total" }]}
								categories={cidsCategories}
								categoryTextWidth={100}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
