import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Select from "react-select";
import { BsSearch } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { AggregatedDashboard, handleEpidemicDashboard } from "@/services/esus/attendanceService";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { AttendanceAggregatorEnum } from "@/utils/enums/AttendanceAggregatorEnum";
import { Option } from "@/@types/Option";
import { SelectOptionsProps } from "@/@types/genericals";

import { LoadingScreen } from "@/components/LoadingScreen";
import { AreaChart } from "@/components/Charts/AreaChart";
import { PieChart } from "@/components/Charts/PieChart";
import { DonutChart } from "@/components/Charts/DonutChart";
import { BarChart } from "@/components/Charts/BarChart";

const epidemicOptions: Option<string>[] = [
	{ label: "Dengue (Ciap A77)", value: "1" },
	{ label: "Sifílis feminina (Ciap x70)", value: "2" },
	{ label: "Hanseniase (Ciap A78)", value: "3" },
	{ label: "Tuberculose (Ciap A70)", value: "4" },
];

export function EpidemicDashboard() {
	const { uf, ibgeCode } = useCurrentAccount();
	const { setTitle } = useLayout();
	const [isLoading, setIsloading] = useState(false);
	const [selectedOptionStartDate, setSelectedOptionStartDate] = useState<string>(
		dayjs().subtract(4, "months").format("YYYY-MM-DD")
	);
	const [selectedOptionEndDate, setSelectedOptionEndDate] = useState<string>(
		dayjs().format("YYYY-MM-DD")
	);

	const [selectedOptionEpidemic, setSelectedOptionEpidemic] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);

	const [aggregatedDashboards, setAggregatedDashboards] = useState<AggregatedDashboard[]>([]);

	function filterAttendanceAggregator(key: keyof typeof AttendanceAggregatorEnum) {
		return (
			aggregatedDashboards.find(
				(aggregatedDashboard) =>
					aggregatedDashboard.aggregator === AttendanceAggregatorEnum[key]
			)?.values || []
		);
	}

	const periodSeries =
		filterAttendanceAggregator("PERIOD").map((attendance) => attendance.total) || [];

	const periodCategories =
		filterAttendanceAggregator("PERIOD").map((attendance) => attendance.key) || [];

	const ageSeries =
		filterAttendanceAggregator("AGEGROUP").map((ageGroup) => ageGroup.total) || [];
	const ageCategories =
		filterAttendanceAggregator("AGEGROUP").map((ageGroup) => ageGroup.key) || [];

	const healthUnitSeries =
		filterAttendanceAggregator("HEALTHUNIT")
			.sort((healthUnitA, healthUnitB) => healthUnitB.total - healthUnitA.total)
			.map((healthUnitGroup) => healthUnitGroup.total) || [];

	const healthUnitsCategories =
		filterAttendanceAggregator("HEALTHUNIT")
			.sort((healthUnitA, healthUnitB) => healthUnitB.total - healthUnitA.total)
			.map((healthUnitGroup) => healthUnitGroup.key) || [];

	const healthTeamsSeries =
		filterAttendanceAggregator("HEALTHTEAM")
			.sort((healthTeamA, healthTeamB) => healthTeamB.total - healthTeamA.total)
			.map((healthTeamGroup) => healthTeamGroup.total) || [];

	const healthTeamCategories =
		filterAttendanceAggregator("HEALTHTEAM")
			.sort((healthTeamA, healthTeamB) => healthTeamB.total - healthTeamA.total)
			.map((healthTeamGroup) => healthTeamGroup.key) || [];

	const genderSeries = filterAttendanceAggregator("GENDER").map(
		(genderGroup) => genderGroup.total
	);
	const genderCategories = filterAttendanceAggregator("GENDER").map(
		(genderGroup) => genderGroup.key
	);

	async function fetchEpidemicDashboard() {
		return handleEpidemicDashboard({
			uf: uf,
			ibgeCode: ibgeCode,
			startDate: selectedOptionStartDate,
			endDate: selectedOptionEndDate,
			epidemicType: selectedOptionEpidemic.value,
		});
	}

	async function fetchDashboard() {
		setIsloading(true);
		setAggregatedDashboards(await fetchEpidemicDashboard());
		setIsloading(false);
	}

	useEffect(() => {
		setTitle("Dados epidemiológicos");
		fetchDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<LoadingScreen
				loading={isLoading}
				messages={[
					"Já estamos processando a consulta do periodo.",
					"aguarde alguns instantes.",
					"Estamos quase lá!",
				]}
			/>
			<Form
				onSubmit={(event) => {
					event.preventDefault();
					fetchDashboard();
				}}
			>
				<Row className="d-flex justify-content-between">
					<Col sm={12} lg={4} className="d-flex align-items-center gap-3">
						<Form.Label className="align-items-center fw-bolder">Período</Form.Label>
						<InputGroup className="mb-3">
							<Form.Control
								type="date"
								placeholder="Data inicial"
								min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
								max={dayjs().format("YYYY/MM/DD")}
								defaultValue={dayjs().subtract(4, "M").format("YYYY-MM-DD")}
								onChange={(event) => setSelectedOptionStartDate(event.target.value)}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<Form.Control
								type="date"
								placeholder="data final"
								defaultValue={dayjs().format("YYYY-MM-DD")}
								min={selectedOptionStartDate}
								max={dayjs().format("YYYY-MM-DD")}
								onChange={(event) => setSelectedOptionEndDate(event.target.value)}
							/>
						</InputGroup>
					</Col>
					<Col sm={12} lg={4} className="d-flex align-items-center gap-3">
						<Form.Label>Epidemia</Form.Label>
						<InputGroup className="mb-3">
							<Select
								className="w-100"
								name="epidemic"
								options={epidemicOptions}
								onChange={(event) =>
									setSelectedOptionEpidemic(event as Option<string>)
								}
								defaultValue={{ value: "", label: "Escolha uma opção" }}
								noOptionsMessage={() => "nenhum resultado encontrado na pesquisa"}
							/>
						</InputGroup>
					</Col>
					<Col sm={12} lg={2}>
						<Button type="submit" className="w-100" disabled={isLoading}>
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</Col>
				</Row>
			</Form>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos mensais
						</Card.Header>
						<Card.Body>
							<AreaChart
								series={[{ data: periodSeries, name: "Total" }]}
								categories={periodCategories}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Faixa etária
						</Card.Header>
						<Card.Body>
							<PieChart series={ageSeries} categories={ageCategories} height={300} />
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Gêneros
						</Card.Header>
						<Card.Body>
							<DonutChart series={genderSeries} labels={genderCategories} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por unidade
						</Card.Header>
						<Card.Body>
							<BarChart
								series={[{ data: healthUnitSeries, name: "Total" }]}
								categories={healthUnitsCategories}
								categoryTextWidth={400}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por equipe
						</Card.Header>
						<Card.Body>
							<BarChart
								series={[{ data: healthTeamsSeries, name: "Total" }]}
								categories={healthTeamCategories}
								categoryTextWidth={400}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
