import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { PermissionEnum } from "@/utils/enums/PermissionEnum";

import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { Restrict } from "@/components/Restrict";

export function AsideMenuMain() {
	const { uf, ibgeCode } = useCurrentAccount();

	return (
		<>
			<AsideMenuItem
				to="/home"
				icon="/media/icons/duotune/general/gen001.svg"
				title="HOME"
				fontIcon="bi-app-indicator"
			/>
			{uf && !ibgeCode && (
				<Restrict permissions={[PermissionEnum.VIEW_STATE_REPORTS]}>
					<AsideMenuItem
						to="/dashboard-estadual/geral"
						icon="/media/icons/duotune/graphs/gra005.svg"
						title="DASHBOARD"
						fontIcon="bi-app-indicator"
					/>
					<AsideMenuItem
						to="/dashboard-estadual/vigilancia-em-saude"
						icon="/media/icons/duotune/graphs/gra003.svg"
						title="VIGILÂNCIA EM SAÚDE"
						fontIcon="bi-app-indicator"
					/>
					<AsideMenuItem
						to="/dashboard-estadual/gestantes"
						icon="/media/icons/duotune/graphs/gra003.svg"
						title="GESTANTES"
						fontIcon="bi-app-indicator"
					/>
					<AsideMenuItem
						to="/dashboard-estadual/triplice-viral"
						icon="/media/icons/duotune/medicine/med007.svg"
						title="TRIPLICE VIRAL"
						fontIcon="bi-app-indicator"
					/>
				</Restrict>
			)}

			{uf && ibgeCode && (
				<>
					<Restrict permissions={[PermissionEnum.VIEW_REPORTS]}>
						<AsideMenuItemWithSub
							to=""
							icon="/media/icons/duotune/files/fil003.svg"
							title="RELATÓRIOS"
							fontIcon="bi-app-indicator"
						>
							<AsideMenuItemWithSub
								to=""
								icon="/media/icons/duotune/general/gen028.svg"
								title="ATENDIMENTOS INDIVIDUAIS"
								fontIcon="bi-app-indicator"
							>
								<AsideMenuItem
									to="/relatorios/atendimentos-individuais/dashboard"
									icon="/media/icons/duotune/graphs/gra004.svg"
									title="DASHBOARD"
									fontIcon="bi-app-indicator"
								/>
								<AsideMenuItem
									to="/relatorios/atendimentos-individuais/atendimentos"
									icon="/media/icons/duotune/files/fil003.svg"
									title="ATENDIMENTOS"
									fontIcon="bi-app-indicator"
								/>
							</AsideMenuItemWithSub>
							<AsideMenuItem
								to="/relatorios/vigilancia-em-saude"
								icon="/media/icons/duotune/medicine/med008.svg"
								title="VIGILÂNCIA EM SAÚDE"
								fontIcon="bi-app-indicator"
							/>
							<AsideMenuItem
								to="/relatorios/gestantes"
								icon="/media/icons/duotune/medicine/med008.svg"
								title="GESTANTES"
								fontIcon="bi-app-indicator"
							/>
							<AsideMenuItem
								to="/relatorios/triplice-viral"
								icon="/media/icons/duotune/medicine/med007.svg"
								title="TRIPLICE VIRAL"
								fontIcon="bi-app-indicator"
							/>
							<AsideMenuItem
								to="/relatorios/busca-ativa-vacinal"
								icon="/media/icons/duotune/files/fil024.svg"
								title="BUSCA ATIVA VACINAL"
								fontIcon="bi-app-indicator"
							/>
						</AsideMenuItemWithSub>
					</Restrict>

					<Restrict permissions={[PermissionEnum.VIEW_INDICATORS]}>
						<AsideMenuItemWithSub
							to=""
							icon="/media/icons/duotune/general/gen017.svg"
							title="COMPONENTES"
							fontIcon="bi-app-indicator"
						>
							<AsideMenuItemWithSub
								to=""
								icon="/media/icons/duotune/coding/cod007.svg"
								title="COMPONENTE VÍNCULO"
								fontIcon="bi-app-indicator"
							>
								<Restrict permissions={[PermissionEnum.VIEW_REPORTS]}>
									<AsideMenuItem
										to="/componente-vinculo/dashboard"
										icon="/media/icons/duotune/graphs/gra004.svg"
										title="DASHBOARD"
										fontIcon="bi-app-indicator"
									/>
								</Restrict>
								<Restrict permissions={[PermissionEnum.VIEW_CITIZEN_MANAGEMENT]}>
									<AsideMenuItem
										to="/componente-vinculo/cadastros"
										icon="/media/icons/duotune/communication/com006.svg"
										title="CADASTROS"
										fontIcon="bi-app-indicator"
									/>
								</Restrict>
							</AsideMenuItemWithSub>
							<AsideMenuItemWithSub
								to=""
								icon="/media/icons/duotune/general/gen026.svg"
								title="COMPONENTE QUALIDADE"
								fontIcon="bi-app-indicator"
							>
								<AsideMenuItem
									to="/componente-qualidade/dashboard"
									icon="/media/icons/duotune/graphs/gra010.svg"
									title="DASHBOARD ESF"
									fontIcon="bi-app-indicator"
								/>
								<AsideMenuItem
									to="/componente-qualidade/indicador/1/detalhes"
									icon="/media/icons/duotune/abstract/abs013.svg"
									title="INDICADORES ESF"
									fontIcon="bi-app-indicator"
								/>
								<Restrict permissions={[PermissionEnum.VIEW_REPORTS]}>
									<AsideMenuItem
										to="/componente-qualidade/desempenho-de-equipes"
										icon="/media/icons/duotune/files/fil003.svg"
										title="DESEMPENHO DE EQUIPES"
										fontIcon="bi-app-indicator"
									/>
								</Restrict>
							</AsideMenuItemWithSub>
						</AsideMenuItemWithSub>
					</Restrict>
				</>
			)}
			{!uf && !ibgeCode && (
				<Restrict>
					<AsideMenuItemWithSub
						to=""
						icon="/media/icons/duotune/files/fil003.svg"
						title="RELATÓRIOS"
						fontIcon="bi-app-indicator"
					>
						<AsideMenuItem
							to="/relatorios/admin/cidade"
							icon="/media/icons/duotune/abstract/abs013.svg"
							title="PREVINE BRASIL"
							fontIcon="bi-app-indicator"
						/>
					</AsideMenuItemWithSub>
				</Restrict>
			)}

			<AsideMenuItemWithSub
				to=""
				icon="/media/icons/duotune/electronics/elc004.svg"
				title="AMBIENTE DE APOIO"
				fontIcon="bi-app-indicator"
			>
				<AsideMenuItem
					to="/ambiente-de-apoio/acesso-a-plataforma"
					icon="/media/icons/duotune/technology/teh003.svg"
					title="ACESSO A PLATAFORMA"
					fontIcon="bi-app-indicator"
				/>
			</AsideMenuItemWithSub>

			<Restrict
				permissions={[
					PermissionEnum.VIEW_PROFESSIONAL_MANAGEMENT,
					PermissionEnum.STATE_PROFESSIONAL_MANAGEMENT,
				]}
			>
				<AsideMenuItem
					to="/gestao-de-profissionais"
					icon="/media/icons/duotune/communication/com014.svg"
					title="GESTÃO DE USUÁRIOS"
					fontIcon="bi-app-indicator"
				/>
			</Restrict>

			<AsideMenuItem
				to="/versoes"
				icon="/media/icons/duotune/files/fil011.svg"
				title="VERSÕES"
				fontIcon="bi-app-indicator"
			/>

			<Restrict permissions={[PermissionEnum.CONFIG_DATA_IMPORT]}>
				<AsideMenuItemWithSub
					to=""
					icon="/media/icons/duotune/coding/cod009.svg"
					title="CONFIGURAÇÕES"
					fontIcon="bi-app-indicator"
				>
					<AsideMenuItem
						to="/configuracoes/banco-de-dados"
						icon="/media/icons/duotune/abstract/abs027.svg"
						title="BANCO DE DADOS"
						fontIcon="bi-app-indicator"
					/>
					<Restrict>
						<AsideMenuItem
							to="/configuracoes/cidades"
							icon="/media/icons/duotune/maps/map008.svg"
							title="GESTÃO DE CIDADES"
							fontIcon="bi-app-indicator"
						/>
						<AsideMenuItem
							to="/configuracoes/gestao-de-perfil"
							icon="/media/icons/duotune/general/gen049.svg"
							title="GESTÃO DE PERFIL"
							fontIcon="bi-app-indicator"
						/>
						<AsideMenuItem
							to="/configuracoes/track-lists"
							icon="/media/icons/duotune/social/soc007.svg"
							title="GESTÃO DE VIDEOS"
							fontIcon="bi-app-indicator"
						/>
					</Restrict>
				</AsideMenuItemWithSub>
			</Restrict>
		</>
	);
}
