import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useNavigate } from 'react-router-dom';

import { CodeInput } from '@/components/CodeInput';
import { NextButton } from '../NextButton';

type Props = {
    handleGoToNextStep: () => void;
	isNextButtonDisabled: boolean;
    setCode: (code: string) => void;
}

export function ValidationCodeForm({ setCode, handleGoToNextStep, isNextButtonDisabled }: Props) {
    const navigate = useNavigate();
    return (
        <section>
            <form className="text-center mb-7">
                <Row className="mb-7">
                    <Col>
                        <img src="/media/icons/duotune/communication/sendMail.svg" alt="email" />
                        <h1 className="fw-bolder mt-3">Confirme sua conta</h1>
                        <p className="mt-5 fs-7">
                            Enviamos um código de confirmação de conta para seu E-mail para concluir
                            o cadastro.
                        </p>
                    </Col>
                </Row>
                <Row className="mb-7">
                    <Col>
                        <div className="d-flex flex-center">
                            <CodeInput onCodeChange={setCode} />
                        </div>
                    </Col>
                </Row>
            </form>
            <Row className="justify-content-evenly mt-4">
                <Col className="d-flex justify-content-center">
                    <NextButton
                        text={"Voltar"}
                        form="registration-form"
                        previous
                        className="bg-muted w-100"
                        onClick={() => navigate("/auth")}
                    />
                </Col>
                <Col className="d-flex justify-content-center">
                    <NextButton
                        text={"Confirmar"}
                        form="registration-form"
                        className="w-100"
                        disabled={isNextButtonDisabled}
                        onClick={handleGoToNextStep}
                        style={{ backgroundColor: "#008FFB" }}
                    />
                </Col>
            </Row>
        </section>

    )
}