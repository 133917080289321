import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import statesAndCities from "@/utils/static/states-and-cities.json";
import { useLayout } from "@/../_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import {
	fetchResumeDiseasesChronicNotTransmissibleOfCities,
	fetchResumeDiseasesChronicTransmissibleOfCities,
} from "@/services/federalUnit/epidemicService";
import { fetchDashboardCities } from "@/services/federalUnit/indicatorService";
import { federalUnits } from "@/utils/federalUnits";
import { getCurrentQuarter } from "@/utils/getQuaterCurrent";
import { CityResumeData } from "@/@types/CityResumeData";
import { FederalUnit } from "@/@types/FederalUnit";
import { Account } from "@/@types/Account";
import { City, StatesAndCities } from "@/@types/statesAndCities";

import { LoadingScreen } from "@/components/LoadingScreen";
import { TableRankingCities } from "./TableRankingCities";
import { Diseases } from "./Diseases";
import { Classification, StateGeographicalNetwork } from "@/components/StateGeographicalNetwork";
import { ResumeDiseasesChronicTransmissibleOfCities } from "./Diseases/ChronicCommunicableDiseases";
import { AbandonmentRateOfTripleViral } from "./AbandonmentRateOfTripleViral";
import { ChildBirth } from "./ChildBirth";
import { ResumeDiseasesChronicNotTransmissibleOfCities } from "./Diseases/ChronicNotCommunicableDiseases";
import { CardDiseases } from "./Diseases/CardDiseases";
import { SemiDonut } from "@/components/Charts/SemiDonut";

export type ResumeCitieEpidemic = {
	city: Account;
	total: number;
};

export function DashboardFederalUnit() {
	const { setTitle } = useLayout();
	const { isLoading, uf } = useCurrentAccount();
	const { cities } = statesAndCities as StatesAndCities;
	const [isLoadingFetch, setIsLoadingFetch] = useState(false);
	const [isLoadingFetchDiseasesTransmissible, setIsLoadingFetchDiseasesTransmissible] =
		useState(false);
	const [isLoadingFetchDiseasesNotTransmissible, setIsLoadingFetchDiseasesNotTransmissible] =
		useState(false);
	const [selectedCity, setSelectedCity] = useState<City>();
	const [dasboardCities, setDashboardCities] = useState<CityResumeData[]>([]);
	const [
		resumeDiseasesChronicTransmissibleOfCities,
		setResumeDiseasesChronicTransmissibleOfCities,
	] = useState<ResumeDiseasesChronicTransmissibleOfCities[]>([]);
	const [
		resumeDiseasesChronicNotTransmissibleOfCities,
		setResumeDiseasesChronicNotTransmissibleOfCities,
	] = useState<ResumeDiseasesChronicNotTransmissibleOfCities[]>([]);

	function getCasesTransmissible(
		locationWithCaseIdentified: ResumeDiseasesChronicTransmissibleOfCities
	) {
		const resume: ResumeDiseasesChronicTransmissibleOfCities = {
			city: locationWithCaseIdentified.city,
			totalHansen: locationWithCaseIdentified.totalHansen,
			totalAttendancesCitizensWithHansen:
				locationWithCaseIdentified.totalAttendancesCitizensWithHansen,
			totalTuberculosis: locationWithCaseIdentified.totalTuberculosis,
			totalAttendancesCitizensWithTuberculosis:
				locationWithCaseIdentified.totalAttendancesCitizensWithTuberculosis,
		};
		return resume;
	}

	function getCasesNotTransmissible(
		locationWithCaseIdentified: ResumeDiseasesChronicNotTransmissibleOfCities
	) {
		const resume: ResumeDiseasesChronicNotTransmissibleOfCities = {
			city: locationWithCaseIdentified.city,
			totalDiabetics: locationWithCaseIdentified.totalDiabetics,
			totalAttendancesCitizensWithDiabetics:
				locationWithCaseIdentified.totalAttendancesCitizensWithDiabetics,
			totalHypertension: locationWithCaseIdentified.totalHypertension,
			totalAttendancesCitizensWithHypertension:
				locationWithCaseIdentified.totalAttendancesCitizensWithHypertension,
		};
		return resume;
	}

	async function handleDashboardCities() {
		return await fetchDashboardCities({
			uf: uf,
			quarter: getCurrentQuarter(),
		});
	}

	async function handleResumeDiseasesChronicTransmissible() {
		return await fetchResumeDiseasesChronicTransmissibleOfCities({
			uf: uf,
			startDate: dayjs().subtract(12, "month").format("YYYY-MM-DD"),
			endDate: dayjs().format("YYYY-MM-DD"),
		});
	}

	async function handleResumeDiseasesChronicNotTransmissible() {
		return await fetchResumeDiseasesChronicNotTransmissibleOfCities({
			uf: uf,
			startDate: dayjs().subtract(12, "month").format("YYYY-MM-DD"),
			endDate: dayjs().format("YYYY-MM-DD"),
		});
	}

	async function fetch() {
		setIsLoadingFetch(true);
		setDashboardCities(await handleDashboardCities());
		setIsLoadingFetchDiseasesTransmissible(true);
		setResumeDiseasesChronicTransmissibleOfCities(
			await handleResumeDiseasesChronicTransmissible()
		);
		setIsLoadingFetchDiseasesTransmissible(false);
		setIsLoadingFetchDiseasesNotTransmissible(true);
		setResumeDiseasesChronicNotTransmissibleOfCities(
			await handleResumeDiseasesChronicNotTransmissible()
		);
		setIsLoadingFetchDiseasesNotTransmissible(false);
		setIsLoadingFetch(false);
	}

	useEffect(() => {
		setTitle("DASHBOARD ESTADUAL");
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const totalCitizensAttendancesWithHansenAllCities: number =
		resumeDiseasesChronicTransmissibleOfCities
			?.map((city) => city.totalAttendancesCitizensWithHansen)
			.reduce((acc, current) => acc + current, 0);
	const totalCitizensAttendancesWithTuberculosisAllCities: number =
		resumeDiseasesChronicTransmissibleOfCities
			?.map((city) => city.totalAttendancesCitizensWithTuberculosis)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensWithHansenAllCities: number = resumeDiseasesChronicTransmissibleOfCities
		?.map((city) => city.totalHansen)
		.reduce((acc, current) => acc + current, 0);

	const totalCitizensWithTuberculosisAllCities: number =
		resumeDiseasesChronicTransmissibleOfCities
			?.map((city) => city.totalTuberculosis)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensAttendancesWithHypertensionAllCities: number =
		resumeDiseasesChronicNotTransmissibleOfCities
			?.map((city) => city.totalAttendancesCitizensWithHypertension)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensAttendancesWithDiabeticsAllCities: number =
		resumeDiseasesChronicNotTransmissibleOfCities
			?.map((city) => city.totalAttendancesCitizensWithDiabetics)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensWithHypertensionAllCities: number =
		resumeDiseasesChronicNotTransmissibleOfCities
			?.map((city) => city.totalHypertension)
			.reduce((acc, current) => acc + current, 0);

	const totalCitizensWithDiabeticsAllCities: number =
		resumeDiseasesChronicNotTransmissibleOfCities
			?.map((city) => city.totalDiabetics)
			.reduce((acc, current) => acc + current, 0);

	function showTooltip(evt: MouseEvent) {
		const tooltip = document.getElementById("map-tooltip");
		if (!tooltip) return;
		const targetElement = evt.target as HTMLElement;
		const targetRect = targetElement?.getBoundingClientRect();

		tooltip.style.display = "block";
		tooltip.style.left = targetRect.left + window.scrollX + 30 + "px";
		tooltip.style.top = targetRect.top + window.scrollY - 150 + "px";
	}

	function hideTooltip(evt: MouseEvent) {
		const tooltip = document.getElementById("map-tooltip");
		if (!tooltip) return;
		setSelectedCity({} as City);
		tooltip.style.display = "none";
	}

	return (
		<div className="overflow-auto" style={{ minWidth: "320px" }}>
			<LoadingScreen loading={isLoadingFetch} />
			<section className="mt-5 p-3">
				<h1 className="text-center text-white bg-primary p-2 mx-2 mb-5 rounded ">
					RANKING DO PREVINE BRASIL POR MUNICIPIO NO ÚLTIMO QUADRIMESTRE
				</h1>
				<Card className="p-3">
					<TableRankingCities rankedCities={dasboardCities} />
				</Card>
			</section>
			<section className="mt-5 p-3">
				<div className="d-flex flex-column text-center justify-content-center p-3 bg-white ">
					<h1 className="text-white bg-primary p-2 mx-2 mb-5 rounded ">
						MAPA ESTADUAL DE DOENÇAS CRÔNICAS NOS ÚLTIMOS 12 MESES
					</h1>
					<Row className="bg-white align-items-center">
						<Col sm={12} md={12} lg={8} xl={8} className="h-100 bg-white">
							{!isLoading && uf && (
								<StateGeographicalNetwork
									federalUnit={
										federalUnits.find(
											(federalUnit) => federalUnit.abbreviation === uf
										) || ({} as FederalUnit)
									}
									hideTooltip={hideTooltip}
									showTooltip={showTooltip}
									setSelectedCity={setSelectedCity}
									classifications={cities
										.filter(
											(city) => city.microrregiao.mesorregiao.UF.sigla === uf
										)
										.map<Classification>((city) => {
											const casesTransmissible =
												resumeDiseasesChronicTransmissibleOfCities
													.map<ResumeDiseasesChronicTransmissibleOfCities>(
														(epidemicData) =>
															getCasesTransmissible(epidemicData)
													)
													.find(
														(c) => c.city.ibgeCode === String(city.id)
													);

											const casesNotTransmissible =
												resumeDiseasesChronicNotTransmissibleOfCities
													.map<ResumeDiseasesChronicNotTransmissibleOfCities>(
														(epidemicData) =>
															getCasesNotTransmissible(epidemicData)
													)
													.find(
														(c) => c.city.ibgeCode === String(city.id)
													);

											return {
												city: {
													locale: city.nome,
													ibgeCode: String(city.id),
												},
												color:
													casesNotTransmissible && casesTransmissible
														? "#009ef7"
														: "#dbdfe9",
											};
										})}
									children={
										<div
											id="map-tooltip"
											style={{
												position: "absolute",
												display: "none",
												zIndex: "5",
											}}
										>
											<div>
												<div className="px-3 bg-primary text-center text-white fs-2">
													{selectedCity?.nome}
												</div>
												<table className="border border-secondary w-100 bg-white">
													<thead>
														<tr className="border border-secondary">
															<th className="border border-secondary">
																DOENÇA
															</th>
															<th className="border border-secondary">
																CASOS
															</th>
															<th className="border border-secondary">
																ATENDIMENTOS
															</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>hanseníase</td>
															<td>
																{resumeDiseasesChronicTransmissibleOfCities
																	.find(
																		(resume) =>
																			resume.city.ibgeCode ===
																			String(selectedCity?.id)
																	)
																	?.totalHansen?.toLocaleString() ||
																	0}
															</td>
															<td>
																{resumeDiseasesChronicTransmissibleOfCities
																	.find(
																		(resume) =>
																			resume.city.ibgeCode ===
																			String(selectedCity?.id)
																	)
																	?.totalAttendancesCitizensWithHansen?.toLocaleString() ||
																	0}
															</td>
														</tr>
														<tr>
															<td>Casos de Tuberculose</td>
															<td>
																{resumeDiseasesChronicTransmissibleOfCities
																	.find(
																		(resume) =>
																			resume.city.ibgeCode ===
																			String(selectedCity?.id)
																	)
																	?.totalTuberculosis?.toLocaleString() ||
																	0}
															</td>
															<td>
																{resumeDiseasesChronicTransmissibleOfCities
																	.find(
																		(resume) =>
																			resume.city.ibgeCode ===
																			String(selectedCity?.id)
																	)
																	?.totalAttendancesCitizensWithTuberculosis?.toLocaleString() ||
																	0}
															</td>
														</tr>
														<tr>
															<td>Hipertensos</td>
															<td>
																{resumeDiseasesChronicNotTransmissibleOfCities
																	.find(
																		(resume) =>
																			resume.city.ibgeCode ===
																			String(selectedCity?.id)
																	)
																	?.totalHypertension?.toLocaleString() ||
																	0}
															</td>
															<td>
																{resumeDiseasesChronicNotTransmissibleOfCities
																	.find(
																		(resume) =>
																			resume.city.ibgeCode ===
																			String(selectedCity?.id)
																	)
																	?.totalAttendancesCitizensWithHypertension?.toLocaleString() ||
																	0}
															</td>
														</tr>
														<tr>
															<td>Diabéticos</td>
															<td>
																{resumeDiseasesChronicNotTransmissibleOfCities
																	.find(
																		(resume) =>
																			resume.city.ibgeCode ===
																			String(selectedCity?.id)
																	)
																	?.totalDiabetics?.toLocaleString() ||
																	0}
															</td>
															<td>
																{resumeDiseasesChronicNotTransmissibleOfCities
																	.find(
																		(resume) =>
																			resume.city.ibgeCode ===
																			String(selectedCity?.id)
																	)
																	?.totalAttendancesCitizensWithDiabetics?.toLocaleString() ||
																	0}
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									}
									height="600"
									width="600"
								/>
							)}
						</Col>
						<Col
							sm={12}
							md={12}
							lg={4}
							xl={4}
							className="d-flex flex-column align-items-center h-100"
						>
							<CardDiseases
								title="Hanseníase"
								value={totalCitizensWithHansenAllCities}
								children={
									<SemiDonut
										descriptionsLabels={["Atendidos", "Não atendidos"]}
										series={
											isLoadingFetchDiseasesTransmissible
												? [0, 0]
												: [
														totalCitizensAttendancesWithHansenAllCities,
														totalCitizensWithHansenAllCities -
															totalCitizensAttendancesWithHansenAllCities,
												  ]
										}
										height={230}
										hideLegend
									/>
								}
							/>
							<CardDiseases
								title="Tuberculose"
								value={totalCitizensWithTuberculosisAllCities}
								children={
									<SemiDonut
										descriptionsLabels={["Atendidos", "Não atendidos"]}
										series={
											isLoadingFetchDiseasesTransmissible
												? [0, 0]
												: [
														totalCitizensAttendancesWithTuberculosisAllCities,
														totalCitizensWithTuberculosisAllCities -
															totalCitizensAttendancesWithTuberculosisAllCities,
												  ]
										}
										height={230}
										hideLegend
									/>
								}
							/>
							<CardDiseases
								title="Hipertensos"
								value={totalCitizensWithHypertensionAllCities}
								children={
									<SemiDonut
										descriptionsLabels={["Atendidos", "Não atendidos"]}
										series={
											isLoadingFetchDiseasesNotTransmissible
												? [0, 0]
												: [
														totalCitizensAttendancesWithHypertensionAllCities,
														totalCitizensWithHypertensionAllCities -
															totalCitizensAttendancesWithHypertensionAllCities,
												  ]
										}
										height={230}
										hideLegend
									/>
								}
							/>
							<CardDiseases
								title="Diabéticos"
								value={totalCitizensWithDiabeticsAllCities}
								children={
									<SemiDonut
										descriptionsLabels={["Atendidos", "Não atendidos"]}
										series={
											isLoadingFetchDiseasesNotTransmissible
												? [0, 0]
												: [
														totalCitizensAttendancesWithDiabeticsAllCities,
														totalCitizensWithDiabeticsAllCities -
															totalCitizensAttendancesWithDiabeticsAllCities,
												  ]
										}
										height={230}
										hideLegend
									/>
								}
							/>
						</Col>
					</Row>
				</div>
			</section>
			<section>
				<Diseases
					resumeDiseasesChronicNotTransmissibleOfCities={
						resumeDiseasesChronicNotTransmissibleOfCities
					}
					resumeDiseasesChronicTransmissibleOfCities={
						resumeDiseasesChronicTransmissibleOfCities
					}
				/>
			</section>
			<section className="mt-5 p-3">
				<h1 className="text-center text-white bg-primary p-2 mx-2 mb-5 rounded ">
					MATERNO INFANTIL
				</h1>
				<ChildBirth uf={uf} />
			</section>
			<section className="mt-5 p-3">
				<AbandonmentRateOfTripleViral uf={uf} />
			</section>
		</div>
	);
}
