import CryptoJS from "crypto-js";
import dayjs from "dayjs";

export function removeDuplicates<Type>(array: Array<unknown>): Type {
	if (!array || !array.length) {
		return [] as unknown as Type;
	}

	return array.filter((value, index) => {
		const _value = JSON.stringify(value);
		return (
			index ===
			array.findIndex((obj) => {
				return JSON.stringify(obj) === _value;
			})
		);
	}) as unknown as Type;
}

export function displaySingularOrPlural(number: number, text: string) {
	const plural = number !== 1 ? "s" : "";
	return `${number ?? 0} ${text}${plural}`;
}

export function onlyNumbers(value: string) {
	return value.replace(/\D/g, "");
}

export function capitalize(fullName: String) {
	return fullName.trim().split(" ").map(name => name.charAt(0).toUpperCase() + name.substring(1).toLowerCase()).join(" ");
}

export function encrypt(value: string) {
	return CryptoJS.AES.encrypt(value, `${dayjs().format("YYYY/MM/DD")}`).toString();
}

export function decrypt(encryptedValue: string) {
	return CryptoJS.AES.decrypt(encryptedValue, `${dayjs().format("YYYY/MM/DD")}`).toString(
		CryptoJS.enc.Utf8
	);
}
