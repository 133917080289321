import { Cid } from "@/@types/Cid";
import { get } from "../api";

type CidsParams = {
	uf: string;
	ibgeCode: string;
	search: string;
};

export async function fetchCids(cidsParams: CidsParams) {
	const response = await get<Cid[]>(`/Cid/v1/Get`, cidsParams);
	return response.data;
}
