import axios, { AxiosResponse } from "axios";

import { getApiInstance } from "@/utils/getApiInstance";

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const authApi = axios.create({
	baseURL: process.env.REACT_APP_AUTH_BASE_URL,
});

export async function get<T>(url: string, params?: any): Promise<AxiosResponse<T>> {
	const api = getApiInstance();
	try {
		const response = await api.get<T>(url, {
			params: params,
		});
		return response;
	} catch (error) {
		console.log(error);
		return {status: 500} as AxiosResponse<T>;
	}
}

export async function post<T>(url: string, params?: any): Promise<AxiosResponse<T>> {
	const api = getApiInstance();
	try {
		const response = await api.post<T>(url, params);
		return response;
	} catch (error) {
		console.log(error);
		return {status: 500} as AxiosResponse<T>;
	}
}