import { Ciap } from "@/@types/Ciap";
import { get } from "../api";

type CiapsParams = {
	uf: string;
	ibgeCode: string;
	search: string;
};
export async function fetchCiaps(ciapsParams: CiapsParams) {
	const response = await get<Ciap[]>(`/Ciap/v1/Get`, ciapsParams);
	return response.data;
}
