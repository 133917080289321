import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { ExportTableType } from "@/@types/ExportTableType";

import { SearchInput } from "@/components/SearchInput";
import { OptionsPopover } from "@/components/Popovers/OptionsPopover";

type Props = {
	fullName: string;
	setFullName: (fullName: string) => void;
	handleExportTable?: (printOption: ExportTableType) => void;
	isPrinting?: boolean;
};

export function PregnancyFilters({ fullName, setFullName, handleExportTable, isPrinting }: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
			}}
		>
			<Row className="d-flex justify-content-between">
				<Col sm={12} md={12} lg={9} xl={9} className=" mb-2">
					<div>
						<label className="form-label text-nowrap">Pesquisar Cidadão:</label>
						<SearchInput
							placeholder="Pesquisar nome"
							value={fullName}
							setValue={setFullName}
						/>
					</div>
				</Col>
				<Col
					sm={12}
					md={12}
					lg={2}
					xl={2}
					className="d-flex align-items-end justify-content-center mb-2"
				>
					{handleExportTable && (
						<div>
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={(props) =>
									OptionsPopover({
										handlePrintClick: () => handleExportTable("print"),
										handleExportExcelClick: () => handleExportTable("excel"),
										...props,
									})
								}
								rootClose
							>
								<Button variant="secondary" className="px-2" disabled={isPrinting}>
									Opções
								</Button>
							</OverlayTrigger>
						</div>
					)}
				</Col>
			</Row>
		</Form>
	);
}
