import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Select from "react-select";
import { FaDatabase } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

import { useLayout } from "@/../_metronic/layout/core";
import { useUnits } from "@/hooks/useUnits";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useDebounce } from "@/hooks/useDebounce";
import { fetchCiaps } from "@/services/esus/ciapService";
import { fetchCids } from "@/services/esus/cidService";
import { fetchProblemPaginated } from "@/services/esus/problemService";
import { formatIdentifier } from "@/utils/formatIdentifier";
import { Paginated } from "@/@types/paginated";
import { SelectOptionsProps } from "@/@types/genericals";
import { Option } from "@/@types/Option";
import { Cid } from "@/@types/Cid";
import { Ciap } from "@/@types/Ciap";
import { Problem } from "@/@types/Problem";

import { TableSkeletons } from "@/components/TableSkeletons";
import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { UnitsSelect } from "@/components/UnitsSelect";

import "./styles.scss";

export function AttendanceReport() {
	const { setTitle } = useLayout();
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const { units } = useUnits();
	const [isLoading, setIsloading] = useState(false);
	const [isLoadingCids, setIsLoadingCids] = useState(false);
	const [isLoadingCiaps, setIsLoadingCiaps] = useState(false);
	const [selectedOptionCode, setSelectedOptionCode] = useState("");
	const [pageNumber, setPageNumber] = useState(1);
	const [cids, setCids] = useState<Cid[]>([]);
	const [ciaps, setCiaps] = useState<Ciap[]>([]);
	const [searchCid, setSearchCid] = useState("");
	const debouncedSearchCid = useDebounce(searchCid, 1500);
	const [searchCiap, setSearchCiap] = useState("");
	const debouncedSearchCiap = useDebounce(searchCiap, 1500);
	const [selectedOptionStartDate, setSelectedOptionStartDate] = useState("");
	const [selectedOptionEndDate, setSelectedOptionEndDate] = useState("");
	const [paginatedProblems, setPaginatedProblems] = useState<Paginated<Problem>>(
		{} as Paginated<Problem>
	);
	const [selectedOptionUnit, setSelectedOptionUnit] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);
	const [selectedOptionGender, setSelectedOptionGender] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);
	const [selectedOptionCid, setSelectedOptionCid] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);
	const [selectedOptionCiap, setSelectedOptionCiap] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);
	const [selectedOptionYoungerAge, setSelectedOptionYoungerAge] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);
	const [selectedOptionOlderAge, setSelectedOptionOlderAge] = useState<SelectOptionsProps>(
		{} as SelectOptionsProps
	);

	const genderOptions = [
		{ value: "", label: "Todos" },
		{ value: "m", label: "Masculino" },
		{ value: "f", label: "Feminino" },
		{ value: "i", label: "Ignorado" },
	];

	const ciapsOptions = [
		{ value: "", label: "TODOS OS CIAPS" },
		...ciaps?.map((ciap) => ({ value: ciap.ciapCode, label: ciap.description })),
	];

	const cidsOptions = [
		{ value: "", label: "TODOS OS CIDS" },
		...cids?.map((cid) => ({ value: cid.cidCode, label: cid.description })),
	];

	async function handleCids(search?: string) {
		if (!search || search.length < 3 || !ibgeCode) return;
		setIsLoadingCids(true);
		setCids(
			await fetchCids({
				uf,
				ibgeCode,
				search: searchCid,
			})
		);
		setIsLoadingCids(false);
		setSelectedOptionCiap({} as SelectOptionsProps);
	}

	async function handleCiaps(search?: string) {
		if (!search || search.length < 3 || !ibgeCode) return;
		setIsLoadingCiaps(true);
		setCiaps(await fetchCiaps({ uf, ibgeCode, search: searchCiap }));
		setIsLoadingCiaps(false);
	}

	async function fetchProblems() {
		if (!(selectedOptionStartDate && selectedOptionEndDate && ibgeCode)) {
			return;
		}
		setIsloading(true);
		setPaginatedProblems(
			await fetchProblemPaginated({
				uf,
				ibgeCode,
				cnes: cnes || selectedOptionUnit.value,
				cids: selectedOptionCode === "cid" ? selectedOptionCid.value : undefined,
				ciaps: selectedOptionCode === "ciap" ? selectedOptionCiap.value : undefined,
				youngerAge: selectedOptionYoungerAge.value,
				olderAge: selectedOptionOlderAge.value,
				startDate: selectedOptionStartDate,
				endDate: selectedOptionEndDate,
				gender: selectedOptionGender.value,
				pageNumber: pageNumber,
			})
		);
		setIsloading(false);
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	useEffect(() => {
		handleCiaps(searchCiap);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchCiap]);

	useEffect(() => {
		handleCids(searchCid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchCid]);

	useEffect(() => {
		if (selectedOptionStartDate && selectedOptionEndDate) {
			fetchProblems();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		setTitle("RELATÓRIO DE ATENDIMENTOS");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="px-2 bg-white pb-2">
			<Form
				className="pt-2"
				onSubmit={(event) => {
					event.preventDefault();
					fetchProblems();
				}}
			>
				<Row className="mx-2">
					<Col lg={4} className="mb-2">
						<Form.Label>Período</Form.Label>
						<div className="d-flex gap-3">
							<InputGroup className="mb-3">
								<Form.Control
									type="date"
									placeholder="Data inicial"
									min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
									max={dayjs().format("YYYY-MM-DD")}
									onChange={(event) =>
										setSelectedOptionStartDate(event.target.value)
									}
								/>
							</InputGroup>
							<InputGroup className="mb-3">
								<Form.Control
									type="date"
									placeholder="data final"
									min={selectedOptionStartDate}
									max={dayjs().format("YYYY-MM-DD")}
									onChange={(event) =>
										setSelectedOptionEndDate(event.target.value)
									}
								/>
							</InputGroup>
						</div>
					</Col>
					<Col lg={2} className="mb-2">
						<Form.Label>Faixa etária</Form.Label>
						<InputGroup className="mb-3">
							<Select
								className="w-50"
								name="initalAge"
								options={Array(123)
									.fill("")
									.map((item, index) => ({
										value: String(index + 1),
										label: String(index + 1),
									}))}
								onChange={(event) =>
									setSelectedOptionYoungerAge(event as Option<string>)
								}
								defaultValue={{ value: "", label: "-" }}
							/>
							<Select
								className="w-50"
								name="finalAge"
								options={Array(123)
									.fill("")
									.map((item, index) => ({
										value: String(index + 1),
										label: String(index + 1),
									}))}
								onChange={(event) =>
									setSelectedOptionOlderAge(event as Option<string>)
								}
								defaultValue={{ value: "", label: "-" }}
							/>
						</InputGroup>
					</Col>
					<Col lg={2}>
						<Form.Label>Gênero</Form.Label>
						<Select
							className="w-100"
							name="gender"
							options={genderOptions}
							onChange={(event) => setSelectedOptionGender(event as Option<string>)}
							defaultValue={{ value: "", label: "selecione..." }}
						/>
					</Col>
					<Col lg={4}>
						<UnitsSelect
							units={units}
							onChange={(event) => setSelectedOptionUnit(event as Option<string>)}
							defaultValue={{ value: "", label: "selecione..." }}
							noOptionsMessage={() => "Nenhuma unidade encontrada"}
						/>
					</Col>
				</Row>
				<Row className="d-flex justify-content-between mx-2 my-2">
					<Col lg={6} className="d-flex gap-5">
						<Form.Check
							inline
							label="Pesquisar por CID"
							className="text-nowrap mt-10"
							name="option"
							type="radio"
							id="cidRadio"
							onChange={() => setSelectedOptionCode("cid")}
							checked={selectedOptionCode === "cid"}
						/>
						<Form.Check
							inline
							label="Pesquisar por CIAP"
							className="text-nowrap mt-10"
							name="option"
							type="radio"
							id="ciapRadio"
							onChange={() => setSelectedOptionCode("ciap")}
							checked={selectedOptionCode === "ciap"}
						/>
					</Col>
					{selectedOptionCode === "ciap" && (
						<Col lg={4}>
							<InputGroup className="mb-3">
								<Form.Label>CIAP</Form.Label>
								<Select
									className="w-100"
									name="ciap"
									isLoading={isLoadingCiaps}
									inputValue={searchCiap}
									onInputChange={(value) => setSearchCiap(value)}
									options={ciapsOptions}
									onChange={(event) =>
										setSelectedOptionCiap(event as Option<string>)
									}
									defaultValue={{ value: "", label: "Pesquisar CIAP" }}
									noOptionsMessage={() =>
										"nenhum resultado encontrado na pesquisa"
									}
								/>
							</InputGroup>
						</Col>
					)}
					{selectedOptionCode === "cid" && (
						<Col lg={4}>
							<InputGroup className="mb-3">
								<Form.Label>CID</Form.Label>
								<Select
									className="w-100"
									name="cid"
									isLoading={isLoadingCids}
									inputValue={searchCid}
									onInputChange={(value) => setSearchCid(value)}
									options={cidsOptions}
									onChange={(event) =>
										setSelectedOptionCid(event as Option<string>)
									}
									defaultValue={{ value: "", label: "Pesquisar CID" }}
									noOptionsMessage={() =>
										"nenhum resultado encontrado na pesquisa"
									}
								/>
							</InputGroup>
						</Col>
					)}
					<Col lg={2} className="align-items-top">
						<Button className="mt-7" type="submit">
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</Col>
				</Row>
			</Form>
			<Card>
				<Card.Body>
					<Table className="mt-10 bg-white" striped bordered hover responsive>
						<thead>
							<tr className="text-center bg-secondary fw-bolder">
								<th colSpan={7}>Tabela Relacionada</th>
							</tr>
							<tr className="bg-secondary fw-bolder">
								<th className="px-2">Data atendimento</th>
								<th>Nome cidadão</th>
								<th className="text-nowrap">CPF</th>
								<th>Data de nascimento</th>
								<th>Profissional</th>
								<th>CID</th>
								<th>CIAP</th>
							</tr>
						</thead>
						<tbody>
							{paginatedProblems?.data && !isLoading
								? paginatedProblems?.data?.map((problem, index) => (
										<tr key={`item-epidemic-data-${index}`}>
											<td className="px-2">
												{problem.attendance?.date &&
													dayjs(problem.attendance?.date).format(
														"DD/MM/YYYY"
													)}
											</td>
											<td>{problem.citizen?.fullName}</td>
											<td className="text-nowrap">
												{problem.citizen?.cpf &&
													formatIdentifier(problem.citizen?.cpf)}
											</td>
											<td>
												{problem.citizen?.birthdate &&
													dayjs(problem.citizen?.birthdate).format(
														"DD/MM/YYYY"
													)}
											</td>
											<td>{problem.attendance?.professional1?.name}</td>
											<td>{problem.cid?.description}</td>
											<td className="pe-3">{problem.ciap?.description}</td>
										</tr>
								  ))
								: isLoading && <TableSkeletons numberOfCells={7} />}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
			{paginatedProblems?.totalRecords ? (
				<PaginationLinks
					itemsPerPage={paginatedProblems.pageSize}
					totalPages={paginatedProblems.totalRecords}
					changeSelectedPage={handleChangePageNumber}
					pageNumber={pageNumber || 0}
				/>
			) : (
				!isLoading && (
					<NoRecordsFeedback
						message={"Nenhum resultado encontrado."}
						icon={<FaDatabase />}
					/>
				)
			)}
		</div>
	);
}
