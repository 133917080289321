import dayjs from "dayjs";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";

import { ReportHeader } from "@/components/Generics/ReportHeader";

import "./styles.css";

type Props = {
	header?: React.ReactNode;
	children?: React.ReactNode;
	footer?: React.ReactNode;
	displayCurrentDate?: boolean;
	printComponentRef?: React.MutableRefObject<HTMLTableElement | null>;
};

export function PrintComponent({
	header,
	children,
	footer,
	displayCurrentDate,
	printComponentRef,
}: Props) {
	const { currentAccount } = useCurrentAccount();
	/*
    const printAction = () => {
        const PAGE_HEIGHT = 1045
        const printElement = document.getElementById("print-component")
        if (printElement) {
            printElement.classList.add("temp-class-for-height")
            const height = printElement.clientHeight
            const numberOfPage = Math.ceil(height / PAGE_HEIGHT)
            const heightWithSingleHeader = numberOfPage * PAGE_HEIGHT
            let requiredHeight = heightWithSingleHeader
            if (numberOfPage > 1) {
                const headerHeight = printElement.getElementsByTagName("thead")?.[0]?.clientHeight
                const footerHeight = printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight
                requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight)
            }
            printElement.style.height = `${requiredHeight}px`
            printElement.classList.remove("temp-class-for-height")
        }
        window.print()
        if (printElement) {
            printElement.style.height = `auto`
        }
    }
        <button className={"print-preview-button"} onClick={printAction}>{"Print Preview"}</button>*/
	return (
		<div ref={printComponentRef}>
			<table className="print-component">
				<thead>
					<tr>
						<th>
							{currentAccount.locale ? (
								<ReportHeader city={currentAccount.locale} />
							) : (
								<></>
							)}
							{header}
						</th>
					</tr>
					{displayCurrentDate && (
						<tr>
							<th>
								<div className="my-2 mx-10 text-end">
									<p>Data: {dayjs().format("DD/MM/YYYY HH:mm")}</p>
								</div>
							</th>
						</tr>
					)}
				</thead>
				<tbody>
					<tr>
						<td>{children}</td>
					</tr>
				</tbody>
				<tfoot className="table-footer">
					<tr>
						<td>{footer}</td>
					</tr>
				</tfoot>
			</table>
		</div>
	);
}
